import React from "react";
import styled, { ThemeProvider, css } from "styled-components";

import Page from "../page";

import useTheme from "@/hooks/use-theme";
import lightTheme from "../../theme/light-theme";

type ContainerProps = {
  $backgroundColor?: string;
  $color?: string;
};
const Container = styled.div<ContainerProps>`
  ${(props) => css`
    background-color: ${props.$backgroundColor};
    color: ${props.$color};
  `}
`;

const Content = styled.section`
  ${(props) => css`
    padding-top: ${props.theme.OutsidePagePadding}px;
    padding-bottom: ${props.theme.OutsidePagePadding}px;
    text-align: center;
  `}
`;

type SectionTitleProps = {
  outerColor?: string;
  innerColor?: string;
};
const SectionTitle = styled.div<SectionTitleProps>`
  ${(props) => css`
    ${props.theme.FontHeaderBold()}
    color: transparent;
    font-size: 70px;
    line-height: 90px;
    margin-bottom: ${props.theme.Padding}px;
    padding-top: 0;
    text-align: center;
    text-transform: uppercase;

    -webkit-text-fill-color: ${props.innerColor}; /* Will override color (regardless of order) */
    -webkit-text-stroke-width: 3px;
    -webkit-text-stroke-color: ${props.outerColor};

    @media ${props.theme.MediaQuery.TabletS.MAX} {
      font-size: 50px;
      line-height: 60px;
    }
  `}
`;

// Sections with background colors
const ColoredSectionFactory = ({
  backgroundColor,
  textColor,
  themeOverride,
}) => {
  return function ColoredSectionInstance({ children }) {
    const theme = useTheme();

    return (
      <Container
        $backgroundColor={backgroundColor(theme.Color)}
        $color={textColor(theme.Color)}
      >
        <ThemeProvider theme={themeOverride}>{children}</ThemeProvider>
      </Container>
    );
  };
};

const WhiteSection = ColoredSectionFactory({
  backgroundColor: (color) => color.PrimaryColor,
  textColor: (color) => color.DarkText,
  themeOverride: lightTheme,
});

const GraySection = ColoredSectionFactory({
  backgroundColor: (color) => color.SecondaryBgColor,
  textColor: (color) => color.DarkText,
  themeOverride: lightTheme,
});

// Section content
type PitchContentProps = {
  children?: any;
};
const PitchContentLayout = ({ children }: PitchContentProps) => (
  <Content>
    <Page.Lane>{children}</Page.Lane>
  </Content>
);

const PitchSectionTitle = ({ outerColor, innerColor, children }) => (
  <SectionTitle {...{ outerColor, innerColor }}>{children}</SectionTitle>
);

const PitchLayout = () => <></>;

export default PitchLayout;
PitchLayout.WhiteSection = WhiteSection;
PitchLayout.GraySection = GraySection;
PitchLayout.Content = PitchContentLayout;
PitchLayout.SectionTitle = PitchSectionTitle;
