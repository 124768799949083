import React from "react";
import styled from "styled-components";
import { Waypoint } from "react-waypoint";

import Error from "../error";
import Loading from "../controls/loading";
import Stack, { StackSpacing } from "../stack";

const FullHeight = styled.div`
  padding: 0 ${(props) => props.theme.Padding}px;
  height: calc(100vh - 220px);
  text-align: center;
`;

const FlexGrow = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const WaypointContainer = styled.div`
  width: 100%;
  height: 80px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export type InfiniteLoaderParams = {
  isLoading: boolean;
  isError: boolean;
  isLoadingMore?: boolean;
  isErrorOnMore?: boolean;
  hasMore?: boolean;
  onLoadMore?: any; // TODO:
  children: React.ReactNode;
};
const InfiniteLoader = ({
  isLoading,
  isError,
  isLoadingMore,
  isErrorOnMore,
  hasMore,
  onLoadMore,
  children,
}: InfiniteLoaderParams) => {
  if (isLoading) {
    return (
      <Loading.Height>
        <Loading.Container>
          <Loading />
        </Loading.Container>
      </Loading.Height>
    );
  } else if (isError) {
    return (
      <FullHeight>
        <Error
          title="Oops!"
          description="Sorry, we cannot load your recommendations."
          hideButton
        />
      </FullHeight>
    );
  }
  let loadMoreElement;

  if (isErrorOnMore) {
    // TODO: Show an error
    loadMoreElement = null;
  } else if (isLoadingMore) {
    loadMoreElement = (
      <Loading.Container>
        <Loading />
      </Loading.Container>
    );
  } else if (hasMore) {
    loadMoreElement = (
      <WaypointContainer>
        <Waypoint onEnter={onLoadMore} bottomOffset="-300px" />
      </WaypointContainer>
    );
  }

  return (
    <FlexGrow>
      <Stack spacing={StackSpacing.LARGE}>
        {children}
        {loadMoreElement}
      </Stack>
    </FlexGrow>
  );
};

export default InfiniteLoader;
