const gradientTheme = (parentTheme) => ({
  ...parentTheme,
  Color: {
    ...parentTheme.Color,
    DarkText: parentTheme.Color.PrimaryColor,
    ButtonTextColor: parentTheme.Color.PrimaryColor,
  },
});

export default gradientTheme;
