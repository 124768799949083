import React from "react";
import styled, { css } from "styled-components";

import Page from "../page";
import PitchLayout from "./pitch-layout";
import Stack, { StackSpacing, StackDirection, StackAlignment } from "../stack";

import Number1Svg from "../assets/numbers/number-1-svg";
import Number2Svg from "../assets/numbers/number-2-svg";
import Number3Svg from "../assets/numbers/number-3-svg";

import useTheme from "../../hooks/use-theme";
import useWindowSize from "../../hooks/use-window-size";

const IconContainer = styled.div`
  ${(props) => {
    const { MediaQuery, MobileMaxWidth } = props.theme;
    const largeSize = 120;
    const smallSize = 100;

    return css`
      & > svg {
        width: ${largeSize}px;
        height: ${largeSize}px;

        @media ${MediaQuery.BetweenWidths(600, MobileMaxWidth)} {
          width: ${smallSize}px;
          height: ${smallSize}px;
        }
      }
    `;
  }}
`;

const PageNoteStyled = styled(Page.Note)`
  font-size: 14px;
`;

const Instruction = styled.div`
  @media ${(props) => props.theme.MediaQuery.TabletL.MIN} {
    width: 250px;
  }
`;

const instructions = [
  {
    title: "Tell us what you’ve already watched",
    description: "Build your watch history quickly with our onboarding tools",
    Icon: Number1Svg,
  },
  {
    title: "We give you great recommendations",
    description:
      "Great personalization across the services that you already use",
    Icon: Number2Svg,
  },
  {
    title: "Watch your new favorite movie or show",
    description:
      "With just one click we’ll tune into the right service and start playing",
    Icon: Number3Svg,
  },
];

const PitchInstruction = ({ icon, title, description }) => (
  <Instruction>
    <Stack
      spacing={StackSpacing.MEDIUM}
      horizontalAlignment={StackAlignment.CENTER}
    >
      {icon}
      <div>
        <Page.H2>{title}</Page.H2>
        <Page.Note>{description}</Page.Note>
      </div>
    </Stack>
  </Instruction>
);

const PitchInstructions = () => {
  const theme = useTheme();
  const { width } = useWindowSize();

  return (
    <PitchLayout.WhiteSection>
      <PitchLayout.Content>
        <Stack>
          <Stack
            direction={
              width >= theme.MobileMaxWidth
                ? StackDirection.ROW
                : StackDirection.COLUMN
            }
            spacing={StackSpacing.MEDIUM}
            horizontalAlignment={StackAlignment.CENTER}
          >
            {instructions.map((item, i) => (
              <PitchInstruction
                icon={
                  <IconContainer>
                    <item.Icon />
                  </IconContainer>
                }
                title={item.title}
                description={item.description}
                key={i}
              />
            ))}
          </Stack>
          <PageNoteStyled>
            Your data is your data. You can download or delete everything Autum
            knows about you whenever you want.
          </PageNoteStyled>
        </Stack>
      </PitchLayout.Content>
    </PitchLayout.WhiteSection>
  );
};

export default PitchInstructions;
