import React from "react";
import styled from "styled-components";

import teaserSilos from "../teaser-data/teaser-silos-raw-data.json";
import Stack, { StackSpacing } from "../stack";
import Carousel from "../silo-components/carousel";
import Poster from "../poster";
import SiloContainer from "@/components/silo-components/silo-container";

const CarouselContainer = styled.div`
  text-align: left;
`;

const TeaserSilos = () => {
  return (
    <CarouselContainer>
      <Stack spacing={StackSpacing.LARGE}>
        {teaserSilos.map(({ blueprintId, content, title, type }) => (
          <SiloContainer {...{ title, type }} key={blueprintId}>
            <Carousel sizingConfig={Poster.SizingConfig}>
              {(content as any).map((item, i) => (
                <Poster
                  item={item}
                  key={`${item.mhid}-${i}`}
                  contentIndex={i}
                />
              ))}
            </Carousel>
          </SiloContainer>
        ))}
      </Stack>
    </CarouselContainer>
  );
};

export default TeaserSilos;
