import React from "react";

import { animated } from "react-spring";
import { usePageTransitionSpring } from "@autum/react-page-transitions";

import Stack from "./stack";

import PitchHeader from "./pitch/pitch-header";
import PitchInstructions from "./pitch/pitch-instructions";
import PitchStats from "./pitch/pitch-stats";
import PitchDiscover from "./pitch/pitch-discover";
import PitchFooter from "./pitch/pitch-footer";

const LandingPage = () => {
  // TODO outer container should be overflow: hidden until animations are complete so scrollbar does not appear
  const { translateY, opacity } = usePageTransitionSpring((stage) => ({
    translateY: stage === "morphing" || stage === "exiting" ? 100 : 0,
    opacity: stage === "morphing" || stage === "exiting" ? 0 : 1,
    // from: {
    //   translateY: 100,
    //   opacity: 0,
    //   overflow: "hidden",
    // },
    // config: { mass: 1, tension: 1000, friction: 100, clamp: true },
  }));

  return (
    <Stack>
      <PitchHeader {...{ opacity }} displaySources displayDemoButton />
      <animated.div
        style={{
          transform: translateY.to(
            (translateY) => `translateY(${translateY}%)`
          ),
        }}
      >
        <PitchInstructions />
        <PitchStats />
        <PitchDiscover />
        <PitchFooter />
      </animated.div>
    </Stack>
  );
};

export default LandingPage;
