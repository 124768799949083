import React, { useState } from "react";
import dynamic from "next/dynamic";
import { animated } from "react-spring";
import { usePageTransitionSpring } from "@autum/react-page-transitions";

import Page from "../page";
import DiscoverSilos from "../silos/discover-silos";
import { MEDIA_TYPES } from "../../util/media-types";
import { DISCOVERY_TYPES } from "../../util/discovery-types";
import { TooltipPosition } from "../controls/tooltip";
import Stack, { StackSpacing } from "../stack";

import useLocalSettings from "../../hooks/use-local-settings";

// We dynamically load these so that the Server doesn't render them with unknown settings
// which will conflict with layout/rendering client-side.
const SourceFilters = dynamic(() => import("./source-filters"), {
  ssr: false,
});
const DiscoveryFilters = dynamic(() => import("./discovery-filters"), {
  ssr: false,
});
const MediaTypeFilters = dynamic(() => import("./media-type-filters"), {
  ssr: false,
});

const Discover = () => {
  const pageContentsStyle = usePageTransitionSpring((stage) => ({
    opacity: stage === "morphing" || stage === "exiting" ? 0 : 1,
  }));
  const [allowSourceFiltering, setAllowSourceFiltering] = useState(false);

  const { settings, setSettings } = useLocalSettings();

  return (
    <animated.div style={pageContentsStyle}>
      <Page.Hero>
        <Stack spacing={StackSpacing.SMALL}>
          <Page.Title>Find something to watch</Page.Title>

          <DiscoveryFilters
            {...{ settings, setSettings }}
            discoveryTypes={DISCOVERY_TYPES}
          />
          <SourceFilters
            {...{
              allowSourceFiltering,
              setAllowSourceFiltering,
            }}
            tooltipPosition={TooltipPosition.BottomCenter}
          />
          <MediaTypeFilters mediaTypes={MEDIA_TYPES} />
        </Stack>
      </Page.Hero>

      <Page.Lane>
        <DiscoverSilos
          sources={allowSourceFiltering ? settings.selectedSources : []}
          discoveryTypes={settings.selectedDiscoveryTypes}
          mediaTypes={settings.selectedMediaTypes}
        />
      </Page.Lane>
    </animated.div>
  );
};

export default Discover;
