import React from "react";

import Page from "../page";
import Footer from "../footer";
import PitchLayout from "./pitch-layout";
import Button, { ButtonVariant } from "../controls/button";
import Stack, { StackDirection, StackSpacing, StackAlignment } from "../stack";

import useTheme from "../../hooks/use-theme";

const PitchFooter = () => {
  const theme = useTheme();

  return (
    <PitchLayout.WhiteSection>
      <PitchLayout.Content>
        <Stack spacing={StackSpacing.MEDIUM}>
          <Stack spacing={StackSpacing.SMALL}>
            <div>
              <Button variant={ButtonVariant.GiantText} href="/auth/signup">
                <PitchLayout.SectionTitle
                  outerColor="transparent"
                  innerColor={theme.Color.GradientColorBottom}
                >
                  I’m just a website standing in front of a person asking them
                  to sign up
                </PitchLayout.SectionTitle>
              </Button>

              <Page.H2>
                (If you don’t get this reference, you need to sign up so we can
                introduce you to this 90's classic)
              </Page.H2>
            </div>

            <Stack
              direction={StackDirection.ROW}
              spacing={StackSpacing.SMALL}
              horizontalAlignment={StackAlignment.CENTER}
            >
              <Button href="/auth/signup" cta>
                Sign Up
              </Button>

              <Button
                href="/explore/manual"
                color={theme.Color.ButtonTextColor}
              >
                Try it Out
              </Button>
            </Stack>
          </Stack>
          <Footer />
        </Stack>
      </PitchLayout.Content>
    </PitchLayout.WhiteSection>
  );
};

export default PitchFooter;
