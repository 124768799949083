import React, { useCallback, useMemo } from "react";

import InfiniteLoader from "../silo-components/infinite-loader";
import Carousel from "../silo-components/carousel";
import Poster from "../poster";

import SiloContainer from "@/components/silo-components/silo-container";

import useApi, { dataFetchReducer } from "../../hooks/use-api";

import { DISCOVERY_TYPES } from "../../util/discovery-types";
import useClientApi from "@/hooks/use-client-api";

const silosDataFetchReducer = dataFetchReducer({
  moreMerge: (d, a) => ({
    silos: [...d.silos, ...a.silos],
    pagingInfo: a.pagingInfo,
  }),
  checkHasMore: (a) => !!a.pagingInfo.next,
});

const silosBuildDataForMore = ({ body, url, state }) => {
  return {
    modifiedBody: state.data.pagingInfo.next.body,
    modifiedUrl: "/api/discover/silos-next",
  };
};

type DiscoverSilosParams = {
  sources: any; // TODO:
  discoveryTypes: any; // TODO:
  mediaTypes: any;
};
const DiscoverSilos = ({
  sources,
  discoveryTypes,
  mediaTypes,
}: DiscoverSilosParams) => {
  const [{ data, ...loadingInfo }, loadMore] = useApi({
    url: "/api/discover/silos",
    method: "POST",
    body: {
      sources,
      discoveryTypes,
      mediaTypes,
    },
    cache: false,
    reducer: silosDataFetchReducer,
    buildDataForMore: silosBuildDataForMore,
  });

  const clientApi = useClientApi();

  const enrichedSilos = useMemo(() => {
    if (data) {
      return data.silos.map((silo) => {
        const discoveryType = DISCOVERY_TYPES.find(
          (t) => t.id === silo.blueprintId
        );
        const icon = discoveryType ? <discoveryType.Icon /> : null;
        const type = discoveryType && discoveryType.name;
        return { ...silo, icon, type };
      });
    }
    return [];
  }, [data]);

  const handleSiloScrolled = useCallback(
    async ({ siloData, scrollIndex }) => {
      try {
        await clientApi("/api/profile/action/silo-scroll", {
          method: "POST",
          data: {
            siloData,
            scrollIndex,
            filteredSources: sources,
            originatingPage: window.location.pathname,
          },
        });
      } catch (err) {
        // TODO: handle the failure case
        console.log("Silo Scroll error", err);
      }
    },
    [sources, clientApi]
  );

  return (
    <InfiniteLoader {...loadingInfo} onLoadMore={loadMore}>
      {enrichedSilos.map(({ title, icon, type, content }, i) => (
        <SiloContainer {...{ title, icon, type }} key={i}>
          <Carousel
            sizingConfig={Poster.SizingConfig}
            onScrolled={handleSiloScrolled}
            siloData={{
              type,
              index: i,
              title,
            }}
          >
            {content.map((item, i) => (
              <Poster item={item} key={`${item.mhid}-${i}`} contentIndex={i} />
            ))}
          </Carousel>
        </SiloContainer>
      ))}
    </InfiniteLoader>
  );
};

export default DiscoverSilos;
