import React from "react";

import Page from "../page";
import Stack from "../stack";
import PitchLayout from "./pitch-layout";
import TeaserSilos from "../silos/teaser-silos";

import useTheme from "../../hooks/use-theme";

const PitchStats = () => {
  const theme = useTheme();

  return (
    <PitchLayout.Content>
      <Stack>
        <div>
          <PitchLayout.SectionTitle
            outerColor="transparent"
            innerColor={theme.Color.BorderColor}
          >
            See Your Picks
          </PitchLayout.SectionTitle>

          <Page.Subtitle>
            We take what you've seen and recommend new titles you actually want
            to watch
          </Page.Subtitle>
        </div>

        <TeaserSilos />
      </Stack>
    </PitchLayout.Content>
  );
};

export default PitchStats;
