import React, { useMemo, useState, useCallback } from "react";
import styled, { css } from "styled-components";
import { lighten, transparentize } from "polished";
import debounce from "lodash/debounce";

import { Sunburst } from "react-vis";
import Card from "../card";

import useTheme from "../../hooks/use-theme";
import useWindowSize from "../../hooks/use-window-size";
import { useResponsive } from "../responsive-provider";

// We need a min height here so the card doesn't jump when we hover over the sunburst
const CardDescription = styled(Card.Description)`
  min-height: 60px;
`;

const SunburstContainer = styled.div`
  & .sunburst {
    margin: 0 auto ${(props) => props.theme.Padding * 3}px;
  }
`;

const INITIAL_PARENT_TITLE = "Genre";

type PathItemProps = {
  isLast?: boolean;
};
const PathItem = styled.span<PathItemProps>`
  color: ${(props) => props.theme.Color.DarkText};

  ${(props) =>
    props.isLast &&
    css`
      ${props.theme.FontHeaderBold()}
      font-size: 16px;
    `}

  ${(props) =>
    !props.isLast &&
    `
    &::after {
      content: " > "
    }
  `}
`;

function getKeyPath(node) {
  if (!node.parent) {
    return [INITIAL_PARENT_TITLE];
  }

  return [(node.data && node.data.name) || node.name].concat(
    getKeyPath(node.parent)
  );
}

function updateData(data, keyPath) {
  const newData = Object.assign({}, data);
  if (data.children) {
    newData.children = data.children.map((child) => updateData(child, keyPath));
  }

  if (keyPath && !keyPath[newData.name]) {
    newData.hex = transparentize(0.8, newData.hex);
  }

  return newData;
}

const addColors = (data, color) => {
  const newData = Object.assign({}, data);
  if (data.children) {
    newData.children = data.children.map((child) => addColors(child, color));
  }
  newData.hex = lighten((newData.depth - 2) * -0.1, color);
  return newData;
};

const getChartText = ({ text, isTouchDevice, currentNodeCount }) => {
  if (currentNodeCount) {
    return <PathItem isLast>{`  (${currentNodeCount})`}</PathItem>;
  } else if (text) {
    return <>{text({ isTouchDevice })}</>;
  }

  return (
    <>
      {isTouchDevice ? "Tap on" : "Hover over"} this chart to see your most
      watched genres
    </>
  );
};

type SunburstProps = {
  genreData?: any;
  text?: ({ isTouchDevice }: { isTouchDevice: boolean }) => string;
};
const GenreSunburst = ({ genreData = {}, text }: SunburstProps) => {
  const [currentNode, setCurrentNode] = useState(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetNode = useCallback(
    debounce((val) => setCurrentNode(val), 50, {
      leading: true,
      trailing: true,
    }),
    []
  );
  const { isTouchDevice } = useResponsive();

  const theme = useTheme();

  const unhoveredData = useMemo(() => {
    // DO NOT use theme.Color.Purple or theme.Color.ButtonColor here:
    const colors = theme.ChartColorArray;

    if (genreData) {
      let data = updateData(genreData, false);

      return {
        ...data,
        children: data.children.map((c, i) => {
          // just cycle through the colors
          const colorIndex = i % colors.length;
          let currentColor = colors[colorIndex];
          return addColors(c, currentColor);
        }),
      };
    }
    return { children: [] };
  }, [genreData, theme]);

  const { pathItems, currentNodeCount, hoveredData } = useMemo(() => {
    if (!currentNode) {
      return { pathItems: [], currentNodeCount: null, hoveredData: null };
    }
    const pathItems = getKeyPath(currentNode).reverse();
    const pathAsMap = pathItems.reduce((res, row) => {
      res[row] = true;
      return res;
    }, {});

    return {
      currentNodeCount: currentNode.count,
      pathItems,
      hoveredData: updateData(unhoveredData, pathAsMap),
    };
  }, [currentNode, unhoveredData]);

  // Calculate the responsive size of the chart
  const { width: windowWidth } = useWindowSize();
  let sunburstWidth = 500;
  if (windowWidth <= theme.MaxWidthSmall) {
    sunburstWidth = windowWidth - 100;
  }

  return (
    <SunburstContainer>
      <CardDescription>
        {pathItems.map((p, i) => (
          <PathItem isLast={i === pathItems.length - 1} key={`path-item-${i}`}>
            {p}
          </PathItem>
        ))}
        {getChartText({ text, isTouchDevice, currentNodeCount })}
      </CardDescription>

      <Sunburst
        className="sunburst"
        hideRootNode
        onValueMouseOver={(node) => {
          debouncedSetNode(node);
        }}
        onValueMouseOut={() => {
          debouncedSetNode(null);
        }}
        style={{
          stroke: theme.Color.PrimaryColor,
          strokeOpacity: 0.3,
          strokeWidth: "1",
          transition: "fill 400ms",
        }}
        colorType="literal"
        getSize={(d) => d.value}
        getColor={(d) => d.hex}
        data={hoveredData || unhoveredData}
        height={sunburstWidth}
        width={sunburstWidth}
      />
    </SunburstContainer>
  );
};

export default GenreSunburst;
