import React from "react";
import styled from "styled-components";

import Page from "@/components/page";
import Button, { ButtonVariant } from "@/components/controls/button";
import Stack, {
  StackDirection,
  StackSpacing,
  StackAlignment,
} from "@/components/stack";

const ButtonStyled = styled(Button)`
  & > div {
    text-decoration: none;
  }
`;

const Img = styled.img`
  width: 26px;
`;

type FooterProps = {
  showLogout?: boolean;
};
const Footer = ({ showLogout }: FooterProps) => (
  <Stack
    direction={StackDirection.COLUMN}
    spacing={StackSpacing.SMALL}
    horizontalAlignment={StackAlignment.CENTER}
  >
    <ButtonStyled
      variant={ButtonVariant.Text}
      href="http://www.mediahound.com"
      externalLink
    >
      Powered by The Entertainment Graph &reg;
    </ButtonStyled>
    <Stack
      direction={StackDirection.ROW}
      spacing={StackSpacing.SMALL}
      horizontalAlignment={StackAlignment.CENTER}
    >
      <Page.Note>&copy; {new Date().getFullYear()}</Page.Note>
      <Img src="/assets/mediaHoundDog-noText.svg" alt="logo" />

      <ButtonStyled
        variant={ButtonVariant.Text}
        href="http://www.mediahound.com"
        externalLink
      >
        MediaHound, Inc.
      </ButtonStyled>

      <Button href="/privacy" variant={ButtonVariant.Text}>
        Privacy
      </Button>
      <>&nbsp;|&nbsp;</>
      <Button href="/terms" variant={ButtonVariant.Text}>
        Terms
      </Button>
      {showLogout && (
        <>
          <>&nbsp;|&nbsp;</>
          <Button action="/api/auth/logout" variant={ButtonVariant.Text}>
            Logout
          </Button>
        </>
      )}
    </Stack>
  </Stack>
);

export default Footer;
