import React from "react";

import Page from "../page";
import Stack from "../stack";
import PitchLayout from "./pitch-layout";
import Button from "../controls/button";
import TeaserUserStats from "../user-analysis/teaser-user-stats";

import useTheme from "../../hooks/use-theme";

const PitchStats = () => {
  const theme = useTheme();

  return (
    <PitchLayout.GraySection>
      <PitchLayout.Content>
        <Stack>
          <div>
            <PitchLayout.SectionTitle
              outerColor={theme.Color.GradientColorBottom}
              innerColor={theme.Color.SecondaryBgColor}
            >
              See Your Stats
            </PitchLayout.SectionTitle>
            <Page.H2>
              We'll analyze all of the movies and TV shows you've seen and
              generate your stats
            </Page.H2>
          </div>
          <div>
            <TeaserUserStats />
            <Button href="/auth/signup" cta>
              Get Your Report Now
            </Button>
          </div>
        </Stack>
      </PitchLayout.Content>
    </PitchLayout.GraySection>
  );
};

export default PitchStats;
