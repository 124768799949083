import React from "react";
import styled, { ThemeProvider, css } from "styled-components";
import { animated } from "react-spring";

import gradientTheme from "../../theme/gradient-theme";

import Page from "../page";
import NoteWithLink from "../note-with-link";
import WrappedItems from "../controls/wrapped-items";
import Button from "../controls/button";
import Stack, { StackSpacing, StackAlignment } from "../stack";

import { SOURCES } from "../../util/sources";

const AnimatedHero = styled(animated.div)`
  ${(props) => css`
    margin-top: ${props.theme.Padding * 5}px;
    flex: 1;
    text-align: center;
  `}
`;

const TaglineImage = styled.img`
  ${(props) => css`
    margin: ${props.theme.Padding * 2}px auto 0;
    max-width: 100%;
    flex: 1;
    text-align: center;

    @media ${props.theme.MediaQuery.TabletL.MAX} {
      margin-bottom: ${props.theme.Padding}px;
    }
  `}
`;

const SOURCE_SIZE = 40;
const SourceIconContainer = styled.div`
  background-color: ${(props) => props.theme.Color.TransparentLight};
  border-radius: 50%;

  width: ${SOURCE_SIZE}px;
  height: ${SOURCE_SIZE}px;
`;

const NewLine = styled.div`
  ${(props) => css`
    display: inline;

    @media ${props.theme.MediaQuery.MobileS.MAX} {
      display: block;
    }
  `}
`;

type PitchProps = {
  opacity?: any;
  displaySources?: boolean;
  displayDemoButton?: boolean;
};
const PitchHeader = ({
  opacity,
  displaySources,
  displayDemoButton,
}: PitchProps) => {
  return (
    <AnimatedHero style={{ opacity }}>
      <Page.Lane>
        <Stack
          spacing={StackSpacing.MEDIUM}
          horizontalAlignment={StackAlignment.CENTER}
        >
          <Stack spacing={StackSpacing.SMALL}>
            <div>
              <TaglineImage
                src="/assets/tagline.svg"
                alt="Find Your Next Binge"
              />

              <Stack spacing={StackSpacing.SMALL}>
                <Page.Subtitle>
                  Streaming services push <strong>their</strong> originals.
                </Page.Subtitle>
                <Page.Subtitle>
                  Find the best thing to watch for <strong>you</strong>,{" "}
                  <NewLine>not them.</NewLine>
                </Page.Subtitle>
              </Stack>
            </div>

            {displaySources && (
              <WrappedItems>
                {SOURCES.map(({ Icon, name }) => (
                  <SourceIconContainer key={name}>
                    <Icon />
                  </SourceIconContainer>
                ))}
              </WrappedItems>
            )}
          </Stack>

          <Stack spacing={StackSpacing.SMALL}>
            <WrappedItems>
              <Button href="/auth/signup" cta>
                Sign Up
              </Button>

              {displayDemoButton && (
                <ThemeProvider theme={gradientTheme}>
                  <Button href="/explore/manual">Try it Out</Button>
                </ThemeProvider>
              )}
            </WrappedItems>

            <NoteWithLink
              text="Already have an account?"
              linkText="Login"
              href="/auth/login"
            />
          </Stack>
        </Stack>
      </Page.Lane>
    </AnimatedHero>
  );
};

export default PitchHeader;
