import React from "react";
import styled from "styled-components";

import Page from "./page";
import Button from "./controls/button";
import Stack, { StackSpacing, StackAlignment } from "./stack";

const CenterText = styled.div`
  text-align: center;

  & > a {
    display: inline-block;
  }
`;

type ErrorParams = {
  title?: string;
  description?: string;
  text?: string;
  link?: string;
  linkText?: string;
  hideButton?: boolean;
};

const Error = ({
  title,
  description,
  text,
  link,
  linkText,
  hideButton,
}: ErrorParams) => (
  <Stack
    spacing={StackSpacing.SMALL}
    verticalAlignment={StackAlignment.CENTER}
    fullHeight
  >
    <CenterText>
      <Page.Title $bigFont>{title || "Oops!"}</Page.Title>
      <Page.H2>
        {description ||
          "Not sure what happened there... but let's get you back."}
      </Page.H2>
      {text && <Page.P>{text}</Page.P>}
    </CenterText>

    {!hideButton && (
      <CenterText>
        <Button href={link || "/"} cta>
          {linkText || "Home"}
        </Button>
      </CenterText>
    )}
  </Stack>
);

export default Error;
