import React from "react";
import { ThemeProvider } from "styled-components";

import lightTheme from "../../theme/light-theme";

import Card, { PaddingType } from "../card";
import theme from "../../theme/theme";
import YearChart from "./year-chart";
import RadialChart from "./radial-chart";
import TopTraitsList from "./top-traits-list";
import GenreSunburst from "./genre-sunburst";
import StatsContributorSilo from "../user-analysis/stats-contributor-silo";

import userProfile from "../teaser-data/landing-page-stats-raw-data.json";
import genreData from "../teaser-data/landing-page-sunburst-raw-data.json";

const DEFAULT_COLOR = theme.Color.Green;
const VARIABLE_COLOR = theme.Color.SearchColor;

const PIE_CHART_SIZE = 200;
const PIE_CHART_WIDTH = 20;

const FakeUserTraits = [
  { mhid: 1, name: "Strong Female Lead" },
  { mhid: 2, name: "Underdog" },
  { mhid: 3, name: "Nonlinear Narrative" },
  { mhid: 4, name: "Blind Ambition" },
  { mhid: 5, name: "Intrigue" },
  { mhid: 6, name: "Spaghetti Western" },
  { mhid: 7, name: "Superhero" },
  { mhid: 8, name: "Campy" },
  { mhid: 9, name: "Boss From Hell" },
  { mhid: 10, name: "Twist Ending" },
];

const TeaserUserStats = () => {
  const radialChartProps = {
    userProfile,
    DEFAULT_COLOR,
    VARIABLE_COLOR,
    PIE_CHART_SIZE,
    PIE_CHART_WIDTH,
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <Card.Cards>
        {userProfile && (
          <StatsContributorSilo {...{ userProfile }} width={66} />
        )}

        <Card title="Top Traits" width={33}>
          {userProfile && (
            <TopTraitsList topTraits={FakeUserTraits} useDefaultGradient />
          )}
        </Card>
      </Card.Cards>

      <Card.Cards>
        <Card title="Media Watched" width={33} padding={PaddingType.BOTH}>
          {userProfile && <RadialChart {...radialChartProps} />}
        </Card>

        <Card title="Titles by Decade" width={66} padding={PaddingType.BOTH}>
          {userProfile && (
            <YearChart
              {...{
                userProfile,
                DEFAULT_COLOR,
                VARIABLE_COLOR,
              }}
            />
          )}
        </Card>
      </Card.Cards>
      <Card.Cards>
        <Card title="Your Genres" width={100} padding={PaddingType.BOTH}>
          {userProfile && genreData && (
            <GenreSunburst
              {...{
                genreData,
              }}
            />
          )}
        </Card>
      </Card.Cards>
    </ThemeProvider>
  );
};

export default TeaserUserStats;
