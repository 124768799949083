import React from "react";
import styled from "styled-components";

import Button, { ButtonVariant } from "./controls/button";
import Page from "./page";

const Inline = styled.div`
  display: inline-block;
  margin-left: ${(props) => props.theme.Padding / 2}px;
`;

type NoteWithLinkParams = {
  text: string;
  linkText: string;
  href: string;
};

const NoteWithLink = ({ text, linkText, href }: NoteWithLinkParams) => (
  <Page.Note as="div">
    {text}
    <Inline>
      <Button href={href} variant={ButtonVariant.Text}>
        {linkText}
      </Button>
    </Inline>
  </Page.Note>
);

export default NoteWithLink;
