import React from "react";

// import { MorphTimings } from "@autum/react-page-transitions";

import { NextPage } from "../@types/next-overrides";

import useAuth from "@/hooks/use-auth";
import Discover from "../components/discover/discover";
import LandingPage from "../components/landing-page";

export { getServerSideProps } from "@/util/auth/get-props";

const IndexPage: NextPage = () => {
  const auth = useAuth();

  if (auth.user) {
    return <Discover />;
  } else {
    return <LandingPage />;
  }
};

// IndexPage.morphTiming = (router, previousPage) => {
//   if (previousPage && previousPage.key.includes("/auth")) {
//     return MorphTimings.SIMULTANEOUSLY;
//   }
//   if (router.route.includes("/auth")) {
//     return MorphTimings.SIMULTANEOUSLY;
//   }
// };

export default IndexPage;
