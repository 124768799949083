import React from "react";
import styled, { css } from "styled-components";

import Page from "@/components/page";
import Tooltip from "@/controls/tooltip";

import Stack, {
  StackSpacing,
  StackDirection,
  StackAlignment,
} from "@/components/stack";

const TitleText = styled(Page.H2)`
  margin-top: 2px;
`;

const IconContainer = styled.div`
  ${(props) => css`
    background-color: ${props.theme.Color.TransparentLight};
    color: ${props.theme.Color.PrimaryColor};
    border-radius: 50%;
    width: 28px;
    height: 28px;

    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
      vertical-align: top;
      width: 22px;
    }

    @media ${props.theme.MediaQuery.TabletS.MAX} {
      width: 24px;
      height: 24px;

      & svg {
        width: 18px;
      }
    }
  `}
`;

type SiloContainerProps = {
  title: string;
  icon?: React.ReactNode;
  type?: string;
  children: JSX.Element;
};
const SiloContainer = ({ title, icon, type, children }: SiloContainerProps) => (
  <Stack spacing={StackSpacing.SMALL}>
    {title && (
      <Stack
        spacing={StackSpacing.XSMALL}
        direction={StackDirection.ROW}
        verticalAlignment={StackAlignment.CENTER}
      >
        {icon && (
          <Tooltip text={type}>
            <IconContainer>{icon}</IconContainer>
          </Tooltip>
        )}
        {title && <TitleText>{title}</TitleText>}
      </Stack>
    )}

    {children}
  </Stack>
);

export default SiloContainer;
